// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover, Icon } from '@plesk/ui-library';
import { messageBox, redirect, api, Locale } from 'jsw';

const Translate = Locale.getTranslate('components.view-switcher');

const ViewSwitcher = ({
    isPowerUserPanel,
    showPowerUserViewWarning,
    learnMoreUrl,
    hideSwitcherUrl,
    disableWelcomeModeUrl,
    changeViewUrl,
    isWelcomeMode,
}) => {
    const [visible, setVisible] = useState(true);
    const [popoverVisible, setPopoverVisible] = useState(isWelcomeMode);
    const [welcomeMode, setWelcomeMode] = useState(isWelcomeMode);
    const targetRef = useRef();

    const handleTogglePopover = () => {
        if (!welcomeMode) {
            setPopoverVisible(!popoverVisible);
        }
    };

    const handleHideSwitcher = () => {
        const locale = Locale.getSection('components.view-switcher');

        messageBox.show({
            type: messageBox.TYPE_YESNO,
            subtype: 'toggle',
            text: locale.lmsg('confirmationBoxText'),
            description: locale.lmsg('confirmationBoxDescription'),
            buttonTitles: {
                yes: locale.lmsg('confirmationBoxButtonYes'),
                no: locale.lmsg('confirmationBoxButtonNo'),
            },
            onYesClick: () => {
                setVisible(false);
                api.post(hideSwitcherUrl);
            },
        });
    };

    const handleChangeView = async e => {
        e.preventDefault();
        const { redirect: url } = await api.post(changeViewUrl);
        if (url) {
            redirect(url);
        }
    };

    const handleOpenLearnMore = e => {
        e.preventDefault();
        if (welcomeMode) {
            disableWelcomeMode();
        }

        redirect(learnMoreUrl);
    };

    const handleClosePopover = () => {
        setTimeout(() => {
            setPopoverVisible(false);

            if (welcomeMode) {
                setWelcomeMode(false);
                disableWelcomeMode();
            }
        }, 0);
    };

    const disableWelcomeMode = () => {
        api.post(disableWelcomeModeUrl);
    };

    const renderActionMessage = () => {
        const actionMessage = (
            <Translate
                content="actionMessage"
                params={{
                    switchLink: (
                        <a
                            onClick={handleChangeView}
                            href="#"
                            data-action={isPowerUserPanel ? 'to-service-provider' : 'to-power-user'}
                        >
                            <Translate
                                content={welcomeMode ? 'welcomeSwitchView' : 'switchView'}
                                params={{ view: <Translate content={isPowerUserPanel ? 'serviceProviderView' : 'powerUserView'} /> }}
                            />
                        </a>
                    ),
                    helpLink: (
                        <a href="#" onClick={handleOpenLearnMore} data-action="learn-more">
                            <Translate content="learnMore" />
                        </a>
                    ),
                }}
            />
        );

        if (welcomeMode) {
            return (
                <Translate
                    content={isPowerUserPanel ? 'welcomePowerUser' : 'welcomeProviderView'}
                    params={{ action: actionMessage }}
                />
            );
        }

        return actionMessage;
    };

    const renderSwitcherPopover = target => (
        <Popover
            visible={popoverVisible}
            target={target}
            targetRef={targetRef}
            title={<Translate content="title" />}
            placement="top-right"
            onClose={handleClosePopover}
            canCloseOnOutsideClick={!welcomeMode}
            canCloseOnEscapePress={!welcomeMode}
            boundariesElement={document.querySelector('.pul-layout')}
            data-type="view-switcher"
        >
            <Translate
                content="currentViewMessage"
                params={{ view: <Translate content={isPowerUserPanel ? 'powerUserView' : 'serviceProviderView'} /> }}
            />
            <p>
                {renderActionMessage()}
            </p>
            {showPowerUserViewWarning && (
                <span>
                    <i className="icon-attention-tr" />
                    {' '}
                    <Translate content="powerUserViewWarning" />
                </span>
            )}
        </Popover>
    );

    if (!visible) {
        return null;
    }

    return (
        <div className="view-switcher">
            {renderSwitcherPopover(
                <button type="button" className="view-switcher__button" onClick={handleTogglePopover} data-action="toggle-view-switcher" ref={targetRef}>
                    <Icon src={require('icons/16/plesk/nav-navigation.svg')} className="view-switcher__button-icon" />
                    <Translate content="title" className="view-switcher__button-text" component="span" />
                </button>
            )}
            <button type="button" className="view-switcher__close" onClick={handleHideSwitcher} data-action="hide-view-switcher" />
        </div>
    );
};

ViewSwitcher.propTypes = {
    isPowerUserPanel: PropTypes.bool.isRequired,
    showPowerUserViewWarning: PropTypes.bool.isRequired,
    learnMoreUrl: PropTypes.string.isRequired,
    hideSwitcherUrl: PropTypes.string.isRequired,
    disableWelcomeModeUrl: PropTypes.string,
    changeViewUrl: PropTypes.string.isRequired,
    isWelcomeMode: PropTypes.bool.isRequired,
};
ViewSwitcher.defaultProps = {
    disableWelcomeModeUrl: '',
};

export default ViewSwitcher;
